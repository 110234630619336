import type React from 'react';
import { useEffect, useLayoutEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import type { Maybe } from 'graphql/jsutils/Maybe';
import { debounce } from '../parsers';

export const useIsomorphicEffect =
  typeof globalThis.window === 'undefined' ? useEffect : useLayoutEffect;

export interface UseComponentSizeState {
  top: number;
  left: number;
  width: number;
  height: number;
}

export type UseComponentSizeConditionCallback = (element: Maybe<Element>) => Maybe<boolean>;

export type UseComponentSizeObserverCallback = (entries: ResizeObserverEntry[]) => void;

export type UseComponentSizeCallback = ([setSize, element, defaultCallback]: [
  Dispatch<SetStateAction<UseComponentSizeState>>,
  Maybe<Element>,
  UseComponentSizeObserverCallback
]) => UseComponentSizeObserverCallback;

/**
 * Hook that uses the ResizeObserver API to track the size of a component.
 *
 * Example usage:
 * const { width, height } = useComponentSize('.my-component');
 *
 * const { width, height } = useComponentSize('.my-other-component', {
 *   condition: useCallback<UseComponentSizeConditionCallback>(
 *     element => element?.classList.contains('my-class'),
 *     [],
 *   ),
 * });
 *
 * @param selector - A CSS selector or DOM element.
 * @param options - An optional object of options.
 * @param options.debounceTime - The debounce time in milliseconds. Set to 0 or `false` to disable debouncing.
 * @param options.condition - A function or boolean value that determines whether the hook should execute (aka if ResizeObserver should observe).
 * @param options.callback - An optional callback function that will be called instead of the default callback when the component size changes.
 * @param options.onResize - An optional callback function that is called when the component size changes.
 * @returns {{width: number, height: number}} The current size of the component.
 */
export const useComponentSize = (
  selector: string | Maybe<Element>,
  {
    debounceTime = 50,
    condition,
    callback,
    onResize,
  }: {
    debounceTime?: number | boolean;
    condition?: boolean | UseComponentSizeConditionCallback;
    callback?: UseComponentSizeCallback;
    onResize?: (size: UseComponentSizeState) => void;
  } = {}
): UseComponentSizeState => {
  const [size, setSize] = useState<UseComponentSizeState>({ top: 0, left: 0, width: 0, height: 0 });

  useEffect(() => {
    const element = typeof selector === 'string' ? document.querySelector(selector) : selector;

    const shouldObserve =
      condition === undefined || (condition instanceof Function ? condition(element) : condition);

    if (!element || !shouldObserve) {
      return () => null;
    }

    const defaultCallback: UseComponentSizeObserverCallback = ([entry]) => {
      const { top, left } = entry.target.getBoundingClientRect();

      if (entry.borderBoxSize) {
        setSize({
          top,
          left,
          width: entry.borderBoxSize[0].inlineSize,
          height: entry.borderBoxSize[0].blockSize,
        });
      } else if (entry.contentRect) {
        setSize({ top, left, width: entry.contentRect.width, height: entry.contentRect.height });
      }
    };

    const observerCallback = callback?.([setSize, element, defaultCallback]) || defaultCallback;

    const resizeObserver = new ResizeObserver(
      Number.isFinite(debounceTime) && Number(debounceTime) > 0
        ? debounce(observerCallback, Math.round(Number(debounceTime)))
        : observerCallback
    );

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  }, [selector, condition, callback, debounceTime]);

  useEffect(() => onResize?.(size), [onResize, size]);

  return size;
};

/**
 * Functional wrapper to check if the pressed key is the specified key before calling the callback
 *
 * Example usage:
 * const onEnter = onKey('Enter');
 * <Component onClick={handleOnClick} onKeyDown={onEnter(handleOnClick)} tabIndex={0} />
 *
 * @param {string} key - The key to check against.
 * @param {function} callback - The callback to execute if the pressed key matches.
 * @return {function} Function that performs the check based on the onKeyPress/onKeyDown event.
 */
const onKey =
  (key: string) =>
  (callback: (event: React.KeyboardEvent | KeyboardEvent) => unknown) =>
  (event: React.KeyboardEvent | KeyboardEvent) =>
    event.key === key && callback(event);

export const onEnter = onKey('Enter');
export const onEscape = onKey('Escape');
export const onTab = onKey('Tab');

/**
 * Generic reduce callback function, recursively lists all children into an array
 *
 * Example usage:
 * layoutComponents.reduce<Slot[]>(listAllChildren, [])
 * [document.body].reduce<HTMLElement[]>(listAllChildren, [])
 * document.body.children.reduce<HTMLElement[]>(listAllChildren, [])
 *
 * @returns {array} Flattened array containing input element(s) and children
 */
export const listAllChildren = <T>(acc: T[], curr: T): T[] => {
  const currentValue = curr as T & {
    children?: T[];
    slots?: { children?: T[] };
  };

  return acc.concat(
    currentValue,
    [...(currentValue?.children || currentValue?.slots?.children || [])]
      .filter(Boolean)
      .reduce<T[]>(listAllChildren, [])
  );
};

/**
 * Recursively tries to find first parent element with specific data attribute
 *
 * Example usage:
 * getParentElementByDataAttribute('accordion', ref.current)
 *
 * @returns {(HTMLElement|null)} The parent element or null
 */
export const getParentElementByDataAttribute = (
  attribute: string,
  element: Maybe<HTMLElement>
): Maybe<HTMLElement> =>
  element === null || element?.dataset?.[attribute]
    ? element
    : getParentElementByDataAttribute(attribute, element?.parentElement);

/**
 * Hook that uses the `window.matchMedia` interface to determine if the document matches a media query string.
 * `matchMedia` is used with a CSS media query and the event listener is fired only every time it passes that breakpoint.
 * This is more performant than listening for a window resize event because it only fires when the breakpoint changes.
 *
 * - On server side the hook will always return `false`
 * - On client side, when hydration kicks in, the hook will calculate the correct value straightaway
 *
 *
 * This might result in hydration errors and they should be suppressed with `suppressHydrationWarning`
 * as there is no way to know on the server what screen size the device has. This approach is done
 * to prevent unnecessary rerenders on hook initialization when by default value is false all the time,
 * but when the matchMedia is calculate it actually may return true and the hook is triggerd twice.
 *
 * @see https://nextjs.org/docs/messages/react-hydration-error#solution-3-using-suppresshydrationwarning
 *
 * By calculating the correct initial value the hook will run only once all the time
 * and it will help with faster TTI (Time to Interactive).
 *
 * @example
 * ```ts
 * const isMobile = useMediaQuery('(max-width: 768px)');
 * ```
 *
 * @param query - A CSS media query string
 * @returns {matches} Boolean value indicating if the query matches
 */
export const useMediaQuery = (query: string): boolean => {
  const initialValue = typeof window !== 'undefined' ? window.matchMedia(query).matches : false;
  const [matches, setMatches] = useState<boolean>(initialValue);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    const handleChange = () => setMatches(window.matchMedia(query).matches);

    handleChange();

    // Add event listener (and add backwards compatibility for Safari)
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
  }, [query]);

  return matches;
};

interface WindowSizeState {
  width: number;
  height: number;
}

/**
 * Hook that tracks the window size using the window resize event.
 *
 * WARNING - If you want to use this hook, please consider using useMediaQueryContext or useMediaQuery instead.
 * Although the listener is debounced, this hook is not performant and should be used sparingly.
 *
 * Example usage:
 * const { width, height } = useWindowSize();
 *
 * @param options - An optional object of options.
 * @param options.debounceTime - The debounce time in milliseconds. Set to 0 or `false` to disable debouncing.
 * @returns {{width: number, height: number}} Object containing the current window size
 */
export const useWindowSize = ({ debounceTime = 50 }: { debounceTime?: number | boolean } = {}) => {
  const [size, setSize] = useState<WindowSizeState>({ width: 0, height: 0 });

  useIsomorphicEffect(() => {
    const updateSize = () => setSize({ width: window.innerWidth, height: window.innerHeight });

    updateSize();

    const handleResize =
      Number.isFinite(debounceTime) && Number(debounceTime) > 0
        ? debounce(updateSize, Math.round(Number(debounceTime)))
        : updateSize;

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
};
