import { css } from 'styled-components';
import { colors } from './colors';
import { media } from './media';

export const typography = {
  h1: css`
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 30px;

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      font-size: 30px;
      line-height: 35px;
    }
  `,
  h2: css`
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-size: 15px;
    line-height: 26px;
    font-weight: 600;
    text-transform: uppercase;
  `,
  h3: css`
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-size: 30px;
    line-height: 28px;
    font-weight: 700;
    text-transform: lowercase;
  `,
  h4: css`
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    text-transform: uppercase;
  `,
  h5: css`
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 32px;
  `,
  body: css`
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.ACCESSIBILITY_GREY};
  `,
  title: css`
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
  `,
  subtitle: css`
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 22px;
  `,
};

export type Typography = keyof typeof typography;
