import type { FC } from 'react';
import Script from 'next/script';

interface ComponentProps {
  enabled?: boolean | null;
  instanceId?: string | null;
}

export const Botify: FC<ComponentProps> = ({ enabled, instanceId }) =>
  enabled &&
  instanceId && (
    <Script
      src={`https://tags.pw.adn.cloud/${instanceId}/activation.js`}
      strategy="afterInteractive"
    />
  );
