/* eslint-disable react/no-unknown-property */
import type { ReactElement } from 'react';
import Head from 'next/head';
import { Environments } from '../../../../utilities/constants';
import { SiteType } from '~/utilities/graphql/codegen';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { AAFFonts } from '~/components/shared/core/typography/AAFFonts';
import { B2CFonts } from '~/components/shared/core/typography/B2CFonts';

const iconFontUrl = `${
  process.env.ENVIRONMENT === Environments.DEV ? process.env.RUNTIME_ORIGIN : ''
}/_static/${process.env.BUILD_TIME}/fonts/gstar-icons.woff2`;

const fontface = `
@font-face {
  font-family: 'gstar-icons';
  src: url(${iconFontUrl}) format('woff2');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'acumin-pro-condensed';
  src: url('/_fes/${process.env.BUILD_TIME}/fonts/AcuminProCond-Bold.woff2') format('woff2');
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  font-stretch: normal;
}
`;

const siteMap = new Map([
  [SiteType.Aaf, AAFFonts],
  [SiteType.Gstar, B2CFonts],
]);

export const PreloadFonts = (): ReactElement => {
  const { siteType } = useAppContext();

  const FontsComponent = siteMap.get(siteType) ?? siteMap.get(SiteType.Gstar)!;

  return (
    <>
      <Head>
        <link
          rel="preload"
          href={`/_fes/${process.env.BUILD_TIME}/fonts/AcuminProCond-Bold.woff2`}
          as="font"
          type="font/woff2"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchpriority="high"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={iconFontUrl}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchpriority="high"
        />
        {/* eslint-disable-next-line react/no-danger */}
        <style type="text/css" dangerouslySetInnerHTML={{ __html: fontface }} />
      </Head>
      <FontsComponent />
    </>
  );
};
