import type { DefaultTheme } from 'styled-components';

type Breakpoint = keyof DefaultTheme['breakpoints'];

function lessThan(theme: DefaultTheme, bp: Breakpoint): string {
  return `(max-width: calc(${theme.breakpoints[bp]} - 1px))`;
}

function greaterThan(theme: DefaultTheme, bp: Breakpoint): string {
  return `(min-width: ${theme.breakpoints[bp]})`;
}

function between(theme: DefaultTheme, minBp: Breakpoint, maxBp: Breakpoint): string {
  return `${greaterThan(theme, minBp)} and ${lessThan(theme, maxBp)}`;
}

const canHover = '(hover: hover)' as const;
const noHover = '(hover: none)' as const;

const finePointer = '(pointer: fine)' as const;
const coarsePointer = '(pointer: coarse)' as const;

const noMotionPreference = '(prefers-reduced-motion: no-preference)' as const;

const portrait = '(orientation: portrait)' as const;

export const media = (theme: DefaultTheme) => ({
  lessThan: (bp: Breakpoint) => lessThan(theme, bp),
  greaterThan: (bp: Breakpoint) => greaterThan(theme, bp),
  between: (minBp: Breakpoint, maxBp: Breakpoint) => between(theme, minBp, maxBp),
  canHover,
  noHover,
  noMotionPreference,
  portrait,
  finePointer,
  coarsePointer,
});
