/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import Head from 'next/head';
import type { AppSettingsObject } from '../../../types/global';
import { Environments } from '../../constants';
import { SiteType } from '~/utilities/graphql/codegen';
import {
  AlgoliaHostMap,
  AmplienceMediaMap,
  CloudinaryImageMap1,
  CloudinaryImageMap2,
  ProductionHost,
} from '~/utilities/constants/siteType.config';

type ContentSquareTrackingType = 'trackPageview' | 'trackPageEvent';

export type AppContextType = {
  /**
   * IETF / BCP 47 language tag separated by a `dash`
   *
   * @example 'en-nl'
   */
  bcp47Locale: string;
  /**
   * POSIX langauge tag separated by an `underscore`
   *
   * @example 'en_nl'
   */
  locale: string;
  country: string;
  /**
   * lowercase language code
   *
   * @example 'nl'
   */
  language: string;
  /**
   * uppercase language code
   *
   * @example 'EN'
   */
  lang: string;
  pushToContentSquare: (trackingType: ContentSquareTrackingType, action: string) => void;
  pushToAppSettings: (appSettings: AppSettingsObject) => void;
  /**
   * Flag indicating if the application is running in production mode or not
   *
   * uses `process.env.ENVIRONMENT` environment variable
   */
  isProduction: boolean;
  siteType: SiteType;
  cloudinaryURL1: string;
  cloudinaryURL2: string;
  amplienceMediaURL: string;
  algoliaCustomHost: string[];
  /**
   * hostname of the site when deployed to production
   */
  productionHost: string;
  isGStar: boolean;
};

const appContextDefaultValues: AppContextType = {
  bcp47Locale: '',
  locale: 'default',
  country: '',
  language: '',
  lang: '',
  pushToContentSquare: () => {},
  pushToAppSettings: () => {},
  isProduction: false,
  siteType: '' as SiteType,
  cloudinaryURL1: '',
  cloudinaryURL2: '',
  amplienceMediaURL: '',
  algoliaCustomHost: [],
  productionHost: '',
  isGStar: true,
};

export const isGStar = (siteType: SiteType) =>
  [SiteType.Gstar, SiteType.Outlet, SiteType.Employeeshop].includes(siteType);

export const AppContext = createContext<AppContextType>(appContextDefaultValues);

export function useAppContext() {
  return useContext(AppContext);
}

type Props = {
  locale: string;
  children: ReactNode;
  siteType: SiteType;
};

export function AppProvider({ locale, siteType, children }: Props) {
  const value = useMemo((): AppContextType => {
    const pushToContentSquare = (trackingType: ContentSquareTrackingType, payload: string) => {
      window._uxa = window._uxa || [];
      window._uxa.push([trackingType, payload]);
    };

    const pushToAppSettings = (appSettings: AppSettingsObject) => {
      window.AppSettings = window.AppSettings || [];
      window.AppSettings = { ...window.AppSettings, ...appSettings };
    };

    const LOCALE_PATTERN = /([a-zA-Z]){2}_([a-zA-Z]){2}$/;
    const [language, country] = LOCALE_PATTERN.test(locale) ? locale.split('_') : ['', ''];
    const lang = language.toUpperCase();
    const isProduction = process.env.ENVIRONMENT === Environments.PRD;
    const bcp47Locale = locale.replace('_', '-');
    const cloudinaryURL1 = CloudinaryImageMap1[siteType];
    const cloudinaryURL2 = CloudinaryImageMap2[siteType];
    const amplienceMediaURL = AmplienceMediaMap[siteType];
    const algoliaCustomHost = AlgoliaHostMap[siteType];
    const productionHost = ProductionHost[siteType];

    return {
      locale,
      country,
      language,
      lang,
      pushToContentSquare,
      pushToAppSettings,
      isProduction,
      bcp47Locale,
      siteType,
      cloudinaryURL1,
      cloudinaryURL2,
      amplienceMediaURL,
      algoliaCustomHost,
      productionHost,
      isGStar: isGStar(siteType),
    } as AppContextType;
  }, [locale, siteType]);

  return (
    <AppContext.Provider value={value}>
      <Head>
        <link rel="preconnect" href={value.cloudinaryURL1} />
        <link rel="preconnect" href={value.cloudinaryURL2} />
        <link rel="preconnect" href={value.amplienceMediaURL} />
      </Head>

      {children}
    </AppContext.Provider>
  );
}
