import { HostNameRegExps } from '../constants';
import { SiteType } from '../graphql/codegen';

export function getSiteTypeByHostname(hostName: string): SiteType | undefined {
  if (HostNameRegExps.GSTAR.test(hostName)) {
    return SiteType.Gstar;
  }

  if (HostNameRegExps.OUTLET.test(hostName)) {
    return SiteType.Outlet;
  }

  if (HostNameRegExps.AAF.test(hostName)) {
    return SiteType.Aaf;
  }

  if (HostNameRegExps.EMPLOYEESHOP.test(hostName)) {
    return SiteType.Employeeshop;
  }

  return undefined;
}
