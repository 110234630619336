/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useMemo } from 'react';
import type { ReactNode } from 'react';
import { useCommonDataQuery, useUserQuery } from '../../graphql/codegen';
import type { CommonData, User } from '../../graphql/codegen';
import { useAppContext } from '../static/AppContext';

type UserContextType = {
  user?: User | null | undefined;
  commonData?: CommonData | null | undefined;
};

const UserContext = createContext<UserContextType>({
  user: { uid: '' },
  commonData: {},
});

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  let user: UserContextType['user'];
  let commonData: UserContextType['commonData'];
  const { locale, siteType } = useAppContext();

  const {
    error: userQueryError,
    loading: userQueryLoading,
    data: userQueryData,
  } = useUserQuery({ variables: { siteType }, ssr: false });

  const {
    error: commonQueryError,
    loading: commonQueryLoading,
    data: commonQueryData,
  } = useCommonDataQuery({ variables: { locale, siteType }, ssr: false });

  if (!userQueryError && !userQueryLoading && userQueryData) {
    user = userQueryData.user;
  }

  if (!commonQueryError && !commonQueryLoading && commonQueryData) {
    commonData = commonQueryData.commonData;
  }

  const value = useMemo(
    () => ({
      user,
      commonData,
    }),
    [user, commonData]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
