export const defaultTheme = {
  colors: {
    neutral: {
      '0': '#ffffff',
      '50': '#f7f7f7',
      '100': '#f0f0f0',
      '200': '#e4e6e7',
      '300': '#c3c2c2',
      '400': '#999999',
      '500': '#787878',
      '600': '#555555',
      '700': '#444444',
      '800': '#303030',
      '900': '#181818',
      '0-70': '#ffffffb3',
      '0-15': '#ffffff26',
      '0-30': '#ffffff33',
    },
    blue: {
      '100': '#e1eaf1',
      '300': '#90bafa',
      '500': '#0096ff',
      '600': '#0078cc',
      '700': '#005a99',
      '800': '#00385f',
      '900': '#26313c',
      '300-50': '#90bafaa6',
    },
    orange: {
      '50': '#fff5eb',
      '100': '#ffe9d9',
      '200': '#ffcea3',
      '300': '#ffb661',
      '400': '#ffa029',
      '500': '#fa8b00',
      '600': '#ef7a06',
      '700': '#964408',
      '800': '#682b08',
      '500-25': '#fa8b0040',
    },
    red: {
      '50': '#fff6f4',
      '100': '#fae3df',
      '300': '#ff9494',
      '500': '#ee0303',
      '600': '#c2311e',
      '650': '#b0293d',
      '500-30': '#e230304d',
    },
    green: {
      '100': '#eeffeb',
      '200': '#ccffc3',
      '300': '#3ed02b',
      '500': '#108a00',
      '600': '#0f8000',
      '800': '#105c26',
      '300-30': '#3ed02b4d',
    },
    slate: {
      '300': '#b4b9bd',
      '400': '#8a939a',
      '500': '#68737d',
      '600': '#4b555e',
      '700': '#2d3740',
      '900': '#212529',
      '900-5': '#2125290d',
      '900-10': '#2125291a',
      '900-50': '#21252980',
      '900-25': '#21252940',
      '900-15': '#21252926',
    },
    background: {
      base: '#ffffff',
      subtle: '#f7f7f7',
      component: {
        header: '#ffffff',
        footer: '#ffffff',
        input: {
          default: '#ffffff',
        },
        shared: {
          warning: '#fff5eb',
          disabled: '#f7f7f7',
          sale: '#b0293d',
          eco: '#105c26',
          'disabled-2': '#b4b9bd',
          success: '#eeffeb',
          focus: '#e1eaf1',
          error: '#fff6f4',
          brand: '#005a99',
        },
        button: {
          primary: {
            rest: '#00385f',
            hover: '#005a99',
            pressed: '#26313c',
            focused: '#00385f',
          },
          solid: {
            light: {
              hover: '#e4e6e7',
              focused: '#ffffff',
              rest: '#ffffff',
              pressed: '#b4b9bd',
            },
            dark: {
              rest: '#303030',
              hover: '#4b555e',
              focused: '#303030',
              pressed: '#0D0D0B',
            },
          },
          outline: {
            light: {
              hover: '#ffffff',
              pressed: '#b4b9bd',
            },
            dark: {
              hover: '#303030',
              pressed: '#4b555e',
            },
          },
        },
        glass: '#ffffffb3',
        'site-banner': '#ffffff',
        'input-elements': {
          default: '#ffffff',
          'active-pressed': '#00385f',
          'active-hover': '#0078cc',
        },
        'loading-1': '#e4e6e7',
      },
      inverse: '#303030',
      mid: '#68737d',
    },
    content: {
      primary: '#0D0D0B',
      secondary: '#6E6E6D',
      inverse: '#ffffff',
      error: '#c2311e',
      sale: '#108a00',
      'sale-alt': '#ee0303',
      tertiary: '#8a939a',
      'brand-accent': '#00385f',
      hyperlink: '#0D0D0B',
      success: '#0f8000',
      warning: '#ef7a06',
      component: {
        buttons: {
          success: {
            rest: '#0D0D0B',
            hover: '#ffffff',
            color: {
              base: '#ffffff',
              hover: '#0D0D0B',
            },
          },
          primary: {
            rest: '#0D0D0B',
            hover: '#ffffff',
            color: {
              base: '#ffffff',
              hover: '#0D0D0B',
            },
          },
          tertiary: {
            rest: '#0D0D0B',
            hover: '#0D0D0B',
            pressed: '#c3c2c2',
            color: {
              base: '#0D0D0B',
              hover: '#ffffff',
            },
          },
          transparent: {
            rest: '#ffffff',
            hover: '#ffffff',
            pressed: '#c3c2c2',
            color: {
              base: '#ffffff',
              hover: '#0D0D0B',
            },
          },
          disabled: {
            rest: '#E7E7E7',
            color: {
              base: '#B6B6B6',
            },
          },
          secondary: {
            rest: '#c3c2c2',
            hover: '#303030',
            color: {
              base: '#303030',
            },
          },
        },
        'dialog-close': {
          base: '#ffffff',
          color: '#0D0D0B',
        },
        header: {
          base: '#ffffff',
          color: '#0D0D0B',
          hover: '#aaa',
          accessibility: '#999',
          highLight: '#0D0D0B',
          modal: {
            base: '#ffffff',
            color: '#0D0D0B',
          },
        },
        footer: {
          color: '#0D0D0B',
          hover: '#aaa',
        },
        'close-button': '#0D0D0B',
        'dot-navigation-button': '#E7E7E7',
        'input-elements': {
          hover: '#e4e6e7',
          pressed: '#c3c2c2',
          focussed: '#f0f0f0',
          active: '#ffffff',
        },
        disabled: '#68737d',
        placeholder: '#b4b9bd',
        'loading-bar': '#b4b9bd',
        skeleton: '#b4b9bd',
        dark: '#0D0D0B',
        light: '#ffffff',
      },
      focus: '#005a99',
    },
    border: {
      success: '#0f8000',
      warning: '#ef7a06',
      base: '#6E6E6D',
      mid: '#8a939a',
      inverse: '#ffffff',
      focused: '#0D0D0B',
      component: {
        disabled: '#e4e6e7',
        'input-elements': {
          default: '#b4b9bd',
          pressed: '#68737d',
          'active-default': '#005a99',
          'active-hover': '#0078cc',
          'active-pressed': '#00385f',
          hover: '#8a939a',
        },
        shared: {
          focus: '#0078cc',
        },
        button: {
          dark: '#0D0D0B',
          light: '#ffffff',
        },
        select: {
          light: '#0D0D0B',
        },
      },
      error: '#ee0303',
      subtle: '#E7E7E7',
    },
    effect: {
      'shadow-10': '#0D0D0B1a',
      'shadow-5': '#0D0D0B0d',
      component: {
        hover: '#0D0D0B26',
        pressed: '#0D0D0B40',
      },
      focus: '#0078cc',
      error: '#e230304d',
      success: '#3ed02b4d',
    },
  },
  font: {
    weight: {
      regular: 'Book',
      bold: 'Bold',
      black: 'Black',
      ultra: 'Ultra',
      medium: 'Medium',
    },
    family: {
      primary: 'Helvetica Now',
      secondary: 'Acumin Pro Condensed',
    },
    size: {
      '1': '10px',
      '2': '12px',
      '3': '14px',
      '4': '16px',
      '5': '18px',
      '6': '20px',
      '7': '24px',
      '8': '32px',
      '9': '48px',
      '10': '56px',
      '11': '64px',
      '12': '72px',
      '13': '96px',
    },
  },
  size: {
    '0': '0px',
    '1': '4px',
    '2': '8px',
    '3': '12px',
    '4': '16px',
    '5': '20px',
    '6': '24px',
    '8': '32px',
    '10': '40px',
    '12': '48px',
    '16': '64px',
    '20': '80px',
    '30': '120px',
    '250': '1000px',
    '0-25': '1px',
    '0-5': '2px',
    '0-75': '3px',
    component: {
      'close-button': '12px',
    },
  },
  breakpoints: {
    // review later
    xs: '320px',
    sm: '768px', // Mobile Breakpoint
    md: '960px',
    lg: '1024px',
    xl: '1250px', // Tablet Breakpoint
    xxl: '1680px',
    xxxl: '1920px',
    // from original theme
    mobile: '360px',
    tablet: '768px',
    'desktop-sm': '1280px',
    'desktop-lg': '1920px',
  },
  padding: {
    '2xs': '8px',
    xs: '12px',
    sm: '16px',
    md: '24px',
    lg: '40px',
    xl: '64px',
    '2xl': '80px',
    none: '0px',
    '3xs': '4px',
    '4xs': '2px',
  },
  borders: {
    width: {
      '0': '0px',
      xs: '1px',
      sm: '2px',
      md: '3px',
    },
    radius: {
      '0': '0px',
      circular: '1000px',
      xs: '1px',
    },
  },
  typography: {
    mobile: {
      heading: {
        md: '24px',
        lg: '32px',
        xl: '48px',
        '2xl': '56px',
        sm: '20px',
        xs: '16px',
      },
      body: {
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        xs: '12px',
        '2xs': '10px',
        '2xl': '24px',
      },
    },
    tablet: {
      heading: {
        md: '24px',
        lg: '32px',
        xl: '56px',
        '2xl': '64px',
        sm: '20px',
        xs: '16px',
      },
      body: {
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        xs: '12px',
        '2xs': '10px',
        '2xl': '24px',
      },
    },
    desktop_sm: {
      heading: {
        md: '32px',
        lg: '48px',
        xl: '64px',
        '2xl': '72px',
        sm: '24px',
        xs: '16px',
      },
      body: {
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        xs: '12px',
        '2xs': '10px',
        '2xl': '24px',
      },
    },
    desktop_lg: {
      heading: {
        md: '32px',
        lg: '56px',
        xl: '72px',
        '2xl': '96px',
        sm: '24px',
        xs: '16px',
      },
      body: {
        sm: '16px',
        md: '18px',
        lg: '20px',
        xl: '24px',
        xs: '12px',
        '2xs': '10px',
        '2xl': '32px',
      },
    },
  },
  spacing: {
    y: {
      '2xs': '4px',
      xs: '8px',
      md: '16px',
      lg: '24px',
      xl: '40px',
      '3xl': '80px',
      '4xl': '120px',
      sm: '12px',
      '2xl': '64px',
    },
    x: {
      '2xs': '4px',
      xs: '8px',
      md: '16px',
      lg: '24px',
      xl: '40px',
      '3xl': '80px',
      '4xl': '120px',
      sm: '12px',
      '2xl': '64px',
    },
  },
  topNavHeight: '54px',
};
