/* eslint-disable react/no-unknown-property */
import type { FC } from 'react';
import Head from 'next/head';

const fontface = `
@font-face {
  font-family: 'Gotham HCo';
  src: url('/_fes/${process.env.BUILD_TIME}/fonts/GothamBlack_normal_normal_subset1.woff2') format('woff2');
  font-weight: 800 900;
  font-display: swap;
  font-stretch: normal;
  unicode-range: U+0020-007E,U+00A1-017E,U+01E6-01E7,U+0440,U+2013,U+2018-201A,U+201C-201E,U+2020-2022,U+2026,U+2032-2033,U+2039-203A,U+20A1,U+20A4,U+20A6-20A7,U+20A9-20AC,U+20B4-20B5,U+20B8-20BA,U+2122;
}

@font-face {
  font-family: 'Gotham HCo';
  src: url('/_fes/${process.env.BUILD_TIME}/fonts/GothamBold_normal_normal_subset1.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-stretch: normal;
  unicode-range: U+0020-007E,U+00A1-017E,U+01E6-01E7,U+0440,U+2013,U+2018-201A,U+201C-201E,U+2020-2022,U+2026,U+2032-2033,U+2039-203A,U+20A1,U+20A4,U+20A6-20A7,U+20A9-20AC,U+20B4-20B5,U+20B8-20BA,U+2122;
}

@font-face {
  font-family: 'Gotham HCo';
  src: url('/_fes/${process.env.BUILD_TIME}/fonts/GothamMedium_normal_normal_subset1.woff2') format('woff2');
  font-weight: 500 600;
  font-display: swap;
  font-stretch: normal;
  unicode-range: U+0020-007E,U+00A1-017E,U+01E6-01E7,U+0440,U+2013,U+2018-201A,U+201C-201E,U+2020-2022,U+2026,U+2032-2033,U+2039-203A,U+20A1,U+20A4,U+20A6-20A7,U+20A9-20AC,U+20B4-20B5,U+20B8-20BA,U+2122;
}

@font-face {
  font-family: 'Gotham HCo';
  src: url('/_fes/${process.env.BUILD_TIME}/fonts/GothamBook_normal_normal_subset1.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-stretch: normal;
  unicode-range: U+0020-007E,U+00A1-017E,U+01E6-01E7,U+0440,U+2013,U+2018-201A,U+201C-201E,U+2020-2022,U+2026,U+2032-2033,U+2039-203A,U+20A1,U+20A4,U+20A6-20A7,U+20A9-20AC,U+20B4-20B5,U+20B8-20BA,U+2122;
}
`;

export const B2CFonts: FC = () => (
  <Head>
    <link
      rel="preload"
      href={`/_fes/${process.env.BUILD_TIME}/fonts/GothamBook_normal_normal_subset1.woff2`}
      as="font"
      type="font/woff2"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fetchpriority="high"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href={`/_fes/${process.env.BUILD_TIME}/fonts/GothamMedium_normal_normal_subset1.woff2`}
      as="font"
      type="font/woff2"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fetchpriority="high"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href={`/_fes/${process.env.BUILD_TIME}/fonts/GothamBold_normal_normal_subset1.woff2`}
      as="font"
      type="font/woff2"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fetchpriority="high"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href={`/_fes/${process.env.BUILD_TIME}/fonts/GothamBlack_normal_normal_subset1.woff2`}
      as="font"
      type="font/woff2"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fetchpriority="high"
      crossOrigin="anonymous"
    />
    {/* eslint-disable-next-line react/no-danger */}
    <style type="text/css" dangerouslySetInnerHTML={{ __html: fontface }} />
  </Head>
);
