import { SiteType } from '~/utilities/graphql/codegen';

export const overrides = {
  [SiteType.Gstar]: {
    font: {
      weight: {
        regular: 400,
        bold: 700,
        black: 900,
        ultra: 900,
        medium: 600,
      },
    },
  },
  [SiteType.Aaf]: {},
  [SiteType.Outlet]: {},
  [SiteType.Employeeshop]: {},
};
