import type { ReactElement } from 'react';
import { SiteType } from '~/utilities/graphql/codegen';

interface Props {
  siteType: SiteType;
}

export const BaseCssLinks = ({ siteType }: Props): ReactElement => {
  if (siteType === SiteType.Aaf) {
    return (
      <>
        <link
          href={`//campaign.appliedartforms.com/${
            process.env.NODE_ENV === 'development' ? `nocache` : process.env.BUILD_TIME
          }/capsules/aaf/css/aaf.content.style.min.css`}
          rel="preload stylesheet"
          as="style"
          media="screen"
        />
      </>
    );
  }

  return (
    <>
      <link
        href={`/_fes/${process.env.BUILD_TIME}/css/baseGrid.min.css`}
        rel="preload stylesheet"
        as="style"
        media="screen"
      />
      <link
        href={`/_fes/${process.env.BUILD_TIME}/css/baseContentBlock.min.css`}
        rel="preload stylesheet"
        as="style"
        media="screen"
      />
      <link
        href={`/_fes/${process.env.BUILD_TIME}/css/baseInjectionContent.min.css`}
        rel="preload stylesheet"
        as="style"
        media="screen"
      />
    </>
  );
};
