/* eslint-disable @typescript-eslint/no-empty-function */
import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useEffect, useRef } from 'react';
import type { VendorEvent } from '../../../types/global';

export type DataLayerContextType = {
  pushToDataLayer: (dataLayerEvent: VendorEvent, isCookieChange?: boolean) => void;
};

const appContextDefaultValues: DataLayerContextType = {
  pushToDataLayer: () => {},
};

export const DataLayerContext = createContext<DataLayerContextType>(appContextDefaultValues);

export function useDataLayerContext() {
  return useContext(DataLayerContext);
}

export function DataLayerProvider({ children }: PropsWithChildren) {
  const router = useRouter();
  const eventsQueueRef = useRef<VendorEvent[]>([]);
  const isReady = useRef(false);

  useEffect(() => {
    if (router.isReady) {
      isReady.current = true;

      if (eventsQueueRef.current.length) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(...eventsQueueRef.current);
      }
    }

    return () => {
      isReady.current = false;
    };
  }, [router.isReady, eventsQueueRef]);

  const value = useMemo(() => {
    const pushToDataLayer = (dataLayerEvent: VendorEvent, toPush?: boolean) => {
      if (isReady.current || toPush) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataLayerEvent);
      } else {
        eventsQueueRef.current.push(dataLayerEvent);
      }
    };

    return {
      pushToDataLayer,
    };
  }, []);

  return <DataLayerContext.Provider value={value}>{children}</DataLayerContext.Provider>;
}
